var siteSwitcher = $(".ch-navigation .switcher");
var navWrap = $(".ch-navigation .links");
var navLi = navWrap.find("> li");
var navLiDropdown = navWrap.find("> .has-dropdown");
var navLink = navLi.find("> a");
var background = $(".dropBackground");
var backdrop = $(".dropOverlay");

var cTriggerEnter = "trigger-enter";
var cTriggerEnterActive = "trigger-enter-active";

var breakpoint = 960;

function handleLeave(e) {
	if ($(window).width() > breakpoint) {
		var trigger = $(this);

		trigger.removeClass(cTriggerEnterActive);
		setTimeout(function () {
			trigger.removeClass(cTriggerEnter);
		}, 100);

		background.removeClass("open").css("width", 0).css("height", 0);

		trigger.removeClass("ba-top ba-right ba-bottom ba-left active");

		setTimeout(function () {
			if (!navLi.hasClass(cTriggerEnter)) backdrop.fadeOut();
		}, 100);

		$(".has-dropdown .dropdown").removeAttr("style");
	}
}

function whatToDo(trigger, edge) {
	// figure out what side is user coming from
	// ? e.g. left

	// first apply "before-animation" class
	// ? e.g. ba-left
	trigger.addClass("ba-" + edge);

	// then wait a sec and apply original position class
	// ? active
	setTimeout(function () {
		trigger.addClass("active");
	}, 100);
}

function handleEnter(e) {
	if ($(window).width() > breakpoint) {
		var trigger = $(this);

		if (trigger.hasClass("has-dropdown")) {
			trigger.addClass(cTriggerEnter);
			backdrop.fadeIn().addClass("active");

			setTimeout(function () {
				if (trigger.hasClass(cTriggerEnter)) {
					trigger.addClass(cTriggerEnterActive);
				}
			}, 125);

			var dropdown = trigger.find(".dropdown");
			var dropdownCoords = dropdown[0].getBoundingClientRect();
			var navCoords = $(".ch-navigation")[0].getBoundingClientRect();

			var offset = trigger.offset();

			// if it bleeds to the left side
			if (dropdownCoords.left < 16) {
				var cssLeft = 16;

				dropdown.css({
					left: -offset.left + cssLeft,
					transform: "translate(0%, 0%)",
				});
				// if it bleeds to the right side
			} else if (dropdownCoords.right > $(window).width() - 16) {
				var difference = $(window).width() - dropdown.outerWidth();
				var cssLeft = difference - 16;

				dropdown.css({
					left: -offset.left + cssLeft,
					transform: "translate(0%, 0%)",
				});
			} else {
				var cssLeft = dropdownCoords.left;
			}

			background.css({
				width: dropdownCoords.width,
				height: dropdownCoords.height,
				top: dropdownCoords.top - navCoords.top,
				left: cssLeft,
			});
			background.addClass("open");

			var el_pos = trigger.offset();
			var edge = closestEdge(
				e.pageX - el_pos.left,
				e.pageY - el_pos.top,
				trigger.width(),
				trigger.height()
			);

			whatToDo(trigger, edge);

			if (e.type == "focusin") {
				navLiDropdown.not(trigger).attr("class", "has-dropdown");
			}
		} else {
			if (e.type == "focusin") {
				navLiDropdown.attr("class", "has-dropdown");
				background.removeClass("open");
				backdrop.fadeOut();
			}
		}
	}
}

function closestEdge(x, y, w, h) {
	var topEdgeDist = distMetric(x, y, w / 2, 0);
	var bottomEdgeDist = distMetric(x, y, w / 2, h);
	var leftEdgeDist = distMetric(x, y, 0, h / 2);
	var rightEdgeDist = distMetric(x, y, w, h / 2);

	var min = Math.min(
		topEdgeDist,
		bottomEdgeDist,
		leftEdgeDist,
		rightEdgeDist
	);
	switch (min) {
		case leftEdgeDist:
			return "left";
		case rightEdgeDist:
			return "right";
		case topEdgeDist:
			return "top";
		case bottomEdgeDist:
			return "bottom";
	}
}

function distMetric(x, y, x2, y2) {
	var xDiff = x - x2;
	var yDiff = y - y2;
	return xDiff * xDiff + yDiff * yDiff;
}

function setDropOverlayPosition() {
	var navPosition = $(".ch-navigation").offset();
	$(".dropOverlay").css(
		"top",
		navPosition.top + $(".ch-navigation").outerHeight()
	);
}

$(setDropOverlayPosition);

$(".ch-navigation .hamburger").click(function () {
	$(this).toggleClass("is-active");
	$(".ch-navigation .for-mobile").toggleClass("active");
});

$(document).ready(function () {
	$(".dropdown .featured-post").parents(".column").addClass("has-featured");
});

navLink.click(function (e) {
	if ($(window).width() < breakpoint) {
		e.preventDefault();
		$(this).parent().toggleClass("active");
	}
});

navLi.hover(handleEnter, handleLeave);

navLi.focusin(handleEnter);

navWrap.hover(
	// mouseenter
	function () {
		var container = $(this);
		setTimeout(function () {
			container.addClass("hovering");
			$(".dropBackground").addClass("hovering");
		}, 300);
	},
	// mouseleave
	function () {
		var container = $(this);
		setTimeout(function () {
			container.removeClass("hovering");
			$(".dropBackground").removeClass("hovering");
		}, 600);
	}
);

siteSwitcher.hover(
	function () {
		backdrop.fadeIn();
	},
	function () {
		backdrop.fadeOut();
	}
);

$(".ch-navigation .switcher .drop-trigger").click(function () {
	var current = $(this),
		dropdown = current.parent().find(".drop-list");

	dropdown.fadeToggle(150);
});

$(".ch-navigation .switcher .drop-list a").click(function (e) {
	// dont do regular click
	e.preventDefault();

	// calculate 30 miute expiry for cookie
	var in30Minutes = 1 / 48;

	// set cookie so users dont see popup
	Cookies.set("ch-popup-country-warning", "closed", { expires: in30Minutes });

	// redirect users, just as expected
	window.location = $(this).attr("href");
});
